import styled, { css } from 'styled-components';

import { media } from 'helpers/breakpoints';
import { ORIENTATION } from 'components/ToolkitV2/CardV2/Card.typed';
import type { OrientationProps } from 'components/ToolkitV2/CardV2/Card.typed';

import type { ThemeTypes } from 'types/Theme.namespace';

const heightProperties = css<OrientationProps>`
  height: ${({ orientationSmall }) =>
    orientationSmall === ORIENTATION.VERTICAL ? '100%' : 'auto'};

  ${media.medium} {
    height: ${({ orientationMedium }) =>
      orientationMedium === ORIENTATION.VERTICAL
        ? '100%'
        : orientationMedium === ORIENTATION.HORIZONTAL
        ? 'auto'
        : undefined};
  }

  ${media.large} {
    height: ${({ orientationLarge }) =>
      orientationLarge === ORIENTATION.VERTICAL
        ? '100%'
        : orientationLarge === ORIENTATION.HORIZONTAL
        ? 'auto'
        : undefined};
  }
`;

interface ContainerProps extends OrientationProps {
  isDisabled?: boolean;
  backgroundColor: ThemeTypes.Colors;
}
const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ orientationSmall }) =>
    orientationSmall === ORIENTATION.VERTICAL ? 'column' : 'row'};
  background-color: ${({ theme, backgroundColor }) =>
    theme.colors[backgroundColor]};

  ${({ isDisabled }) => isDisabled && 'opacity: 0.48; cursor: not-allowed;'};
  ${heightProperties};

  ${media.medium} {
    flex-direction: ${({ orientationMedium, orientationSmall }) =>
      (orientationMedium || orientationSmall) === ORIENTATION.VERTICAL
        ? 'column'
        : 'row'};
  }

  ${media.large} {
    flex-direction: ${({
      orientationLarge,
      orientationMedium,
      orientationSmall,
    }) =>
      (orientationLarge || orientationMedium || orientationSmall) ===
      ORIENTATION.VERTICAL
        ? 'column'
        : 'row'};
  }
`;

interface CardBodyProps extends OrientationProps {
  gapSmall?: ThemeTypes.Spacing;
  gapMedium?: ThemeTypes.Spacing;
  gapLarge?: ThemeTypes.Spacing;
  paddingSmall: ThemeTypes.Spacing;
  paddingMedium?: ThemeTypes.Spacing;
  paddingLarge?: ThemeTypes.Spacing;
  fillContainer?: boolean;
}
const CardBody = styled.div<CardBodyProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ gapSmall, theme }) => gapSmall && theme.spacing[gapSmall]};
  width: ${({ fillContainer }) => (fillContainer ? '100%' : 'auto')};
  padding: ${({ paddingSmall, theme }) => theme.spacing[paddingSmall]};
  ${heightProperties}

  ${media.medium} {
    padding: ${({ paddingMedium, theme }) =>
      paddingMedium && theme.spacing[paddingMedium]};
    gap: ${({ gapMedium, theme }) => gapMedium && theme.spacing[gapMedium]};
  }

  ${media.large} {
    padding: ${({ paddingLarge, theme }) =>
      paddingLarge && theme.spacing[paddingLarge]};
    gap: ${({ gapLarge, theme }) => gapLarge && theme.spacing[gapLarge]};
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

interface ImageContainerProps {
  trailingImageSmall?: boolean;
  trailingImageMedium?: boolean;
  trailingImageLarge?: boolean;
}

const ImageContainer = styled.div<ImageContainerProps>`
  order: ${({ trailingImageSmall }) => (trailingImageSmall ? 1 : 0)};

  ${media.medium} {
    order: ${({ trailingImageMedium }) => (trailingImageMedium ? 1 : 0)};
  }

  ${media.large} {
    order: ${({ trailingImageLarge }) => (trailingImageLarge ? 1 : 0)};
  }
`;

export { Container, CardBody, Footer, ImageContainer };
