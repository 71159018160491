import * as Styled from 'components/ToolkitV2/CardV2/Card.styled';

import type { CardProps } from 'components/ToolkitV2/CardV2/Card.typed';

function Card(props: CardProps) {
  const {
    header,
    body,
    footer,
    orientationSmall,
    orientationMedium,
    orientationLarge,
    isDisabled,
    className,
    image,
    paddingSmall,
    paddingMedium,
    paddingLarge,
    gapSmall,
    gapMedium,
    gapLarge,
    fillContainer = true,
    trailingImageSmall,
    trailingImageMedium,
    trailingImageLarge,
    backgroundColor = 'WHITE',
  } = props;

  return (
    <Styled.Container
      className={className}
      orientationSmall={orientationSmall}
      orientationMedium={orientationMedium}
      orientationLarge={orientationLarge}
      isDisabled={isDisabled}
      backgroundColor={backgroundColor}
    >
      <Styled.ImageContainer
        {...{ trailingImageSmall, trailingImageMedium, trailingImageLarge }}
      >
        {image}
      </Styled.ImageContainer>
      <Styled.CardBody
        orientationSmall={orientationSmall}
        orientationMedium={orientationMedium}
        orientationLarge={orientationLarge}
        {...{
          paddingSmall,
          paddingMedium,
          paddingLarge,
          gapSmall,
          gapMedium,
          gapLarge,
          fillContainer,
        }}
      >
        {header}
        {body}
        {footer && <Styled.Footer>{footer}</Styled.Footer>}
      </Styled.CardBody>
    </Styled.Container>
  );
}

export { Card };
